import styled from "styled-components";
import { mqCustom, mqDesktop, mqTablet } from "../../styled/helpers/helpers";

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  top: 0;

  main {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: start;
    max-width: ${props => props.isMapVisible ? "1920px" : "1600px"};
    width: 100%;
    height: 100%;
  }

  button {
    background: transparent;
  }

  ${mqCustom(200)`
    *::-webkit-scrollbar {
      width: 4px;
    }
    
    *::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey; 
      border-radius: 10px;
    }
    
    *::-webkit-scrollbar-thumb {
      background: #777; 
      border-radius: 10px;
    }
    
    *::-webkit-scrollbar-thumb:hover {
      background: #d7d7d7; 
    }
  `}

   ${mqTablet`

  `}

  ${mqDesktop`

  `}
`;

export const Title = styled.h2`
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #4f4f4f;
  font-weight: 600;
`

export const LoadingFilter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 2rem;
    p {
      color: #778899;
      font-style: italic;
    }
`

export const AsideFilter = styled.div`
  position: absolute;
  z-index: 20;
  top: 0;
  width: 100%;

  input::placeholder {
    color: #777;
    font-size: 11pt;
  }

  .filter {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 80px - 60px);
    gap: 1rem;
    flex-grow: 2;
    width: 100%;
    margin-top: 80px;
    padding: 1rem;
    padding-top: 20px;
    background: #fff;
    flex-direction: row;
    align-items: start;
    flex-direction: column;
    overflow-y: scroll;
    padding-bottom: 100px;

    ${mqTablet`
      position: inherit;
      width: 400px;
      padding-bottom: 80px;
    `}
  }

  .locale {
    width: 100%;

    .inputContainer {
      position: relative;

      input {
        border: 1px solid #d7d7d7;
        padding: 0.5rem;
        width: 100%;
      }
  
      .neighborhoodOptions {
        position: absolute;
        top: 42px;
        width: 100%;
        max-height: 200px;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        background-color: #f3f3f3;
        border: 1px solid #d7d7d7;
        box-shadow: 0px 5px 10px #00000022;
        z-index: 10;

        optgroup {
          margin-top: 0.5rem;
          padding-left: 0.25rem;
          color: #777;
        }
  
        .option {
          padding: 0.25rem 0.5rem;
          cursor: pointer;
          color: #83786f;
          font-size: 0.9em;
  
          span {
            font-weight: 600;
          }
        }
  
        .option:hover {
          background-color: #83786f34;
          color: #4f4f4f;
        }
      }
    }

    .buttonArea {
      display: flex;
      flex-wrap: wrap;
      gap: 0.25rem;
      padding: 0.5rem 0;
  
      button {
        display: flex;
        text-wrap: nowrap;
        gap: 0.25rem;
        align-items: center;
        padding: 0.125rem 0.5rem;
        color: #fff;
        font-size: 10pt;
        background-color: #83786f;

        svg {
          color: #fff;
        }
      }
    }
  }

  summary {
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #4f4f4f;
    font-weight: 600;
    font-size: 11pt;
  }

  .releaseSwitch {
    display: flex;
    gap: 0.5rem;
    margin: 1rem 0;
  }

  .propertyType {
    display: flex;
    flex-direction: column;

    summary {
      font-size: 12pt;
    }

    .buttonArea {
      display: flex;
      gap: 0.25rem;
      padding: 0.5rem;
      flex-wrap: wrap;
    }

    button {
      padding: 0.125rem 0.5rem;
      border: 1px solid #d7d7d7;
      color: #777;
      font-size: 10pt;
      background-color: transparent;
      text-wrap: nowrap;
    }

    button.typeChecked {
      padding: 0.125rem 0.5rem;
      border: none;
      color: #fff;
      font-size: 10pt;
      font-weight: 500;
      background-color: #83786f;
    }
  }

  .priceSlider {
    display: flex;
    flex-direction: column;
    width: 100%;

    .sliderContainer {
      display: flex;
      width: 100%;
      justify-content: center;
    }

    .minMax {
      display: flex;
      flex-direction: column;
      width: 100%;
      color: #777;
      margin-bottom: 1rem;

      div {
        display: flex;
        gap: 0.5rem;
        width: 100%;
        justify-content: space-between;

        input {
          flex-grow: 1;
          padding: 0.25rem;
          width: 40%;
          border: 1px solid #d7d7d7;
          transition: .5s;

          :focus {
            border: 1px solid #2748C2;
          }
        }
      }
      
      .invalidInput {
        border: 1px solid #D93025;
      }

      .errorMessage {
        color: #D93025;
        font-size: 10pt;
        margin: 0.25rem 0;
      }
    }
  }

  .cleanFilters {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    background: #fff;
    position: sticky;
    bottom: 0;
    border-top: 1px solid #d7d7d7;
    width: 100%;
    box-shadow: 0 -20px 25px #ffffffee;
    gap: 0.5rem;
    height: 60px;

    .hideFilterButton {
      background-color: #2748C2;

      ${mqTablet`
        display: none;
        padding: 1rem;

        button {
          padding: 1rem;
        }
      `}
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      padding: 0.75rem;
      color: #fff;
      font-weight: 700;
      font-size: 10pt;
      background: #8acc2c;
    }
  }

  ${mqTablet`
    position: sticky;
    width: 400px;
    z-index: 1;
  `}
`;

export const Tabs = styled.div`
  display: flex;
  gap: 0.5rem;
  width: 100%;

  button {
    border-bottom: 2px solid #777777;
    flex-grow: 1;
    background: transparent;
    font-weight: 600;
    padding-bottom: 0.5rem;
    color: #777;
  }

  button.selectedTab {
    border-bottom: 2px solid #2748C2;
    color: #2748C2;
  }
`

export const Spaces = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .buttonArea {
    display: flex;
    gap: 0.75rem;
  }

  button {
    padding: 0.25rem;
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid #d7d7d7;
    color: #777;
  }

  button.selected {
    border: none;
    background-color: #83786f;
    color: #fff;
    font-weight: 500;
  }

  ${mqDesktop`
    flex-grow: 1;
  `}
`

export const Features = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .buttonArea {
    display: flex;
    gap: 0.25rem;
    padding: 0.5rem;
    flex-wrap: wrap;

    button {
      padding: 0.125rem 0.5rem;
      border: 1px solid #d7d7d7;
      color: #777;
      font-size: 10pt;
      background-color: transparent;
      text-wrap: nowrap;
    }

    button.featureChecked {
      padding: 0.125rem 0.5rem;
      border: none;
      color: #fff;
      font-size: 10pt;
      background-color: #83786f;
    }
  }

  ${mqDesktop`
    flex-grow: 1;
  `}
`

export const LoadingData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  text-align: center;
  padding-top: 2rem;
  min-width: 250px;
  flex-grow: 1;
  max-width: ${props => (!props.isMapVisible && !props.isFilterVisible) ? "1600px" : "1080px"};

  min-height: 10rem;
  margin-top: 20rem;

  p {
    color: #778899;
    font-style: italic;
  }
`

export const NoData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
  min-width: 250px;
  flex-grow: 1;
  max-width: ${props => (!props.isMapVisible && !props.isFilterVisible) ? "1600px" : "1080px"};

  min-height: 1rem;

  p {
    color: #778899;
    font-style: italic;
    margin: 0;
  }
`
