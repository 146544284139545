import React, { Fragment, useState, useEffect } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { Home } from 'styled-icons/boxicons-regular/Home';
import { Heart } from 'styled-icons/boxicons-regular/Heart';
import { User } from 'styled-icons/boxicons-regular/User';
import {File} from 'styled-icons/boxicons-regular/File';
import { Enter } from 'styled-icons/icomoon/Enter';
import { Cogs } from 'styled-icons/icomoon/Cogs';
import { Dashboard } from 'styled-icons/octicons/Dashboard';
import { PeopleOutline } from 'styled-icons/material/PeopleOutline';
import { transitions } from 'polished';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { RiFileList2Line } from 'react-icons/ri';

import { BaseLabel } from '../../../../styled/labels/labels';
import { COLOR_2, COLOR_1, COLOR_13 } from '../../../../styled/variables/variables';
import { iconBaseStyle, pointer, noScrollBar, mqTablet, mqDesktop, mqCustom } from '../../../../styled/helpers/helpers';

import userProfileService from '../../../../services/user-profile/user-profile.service';

import eventsConstants from '../../../../constants/events';
import domEventsUtil from '../../../../utils/dom-events/dom-events.util';
import pathsConstants from '../../../../constants/paths';
import SocialIcons from '../../../../components/social-icons';

const NavItemList = ({
  isAuthenticated,
  onItemClick,
  user,
}) => {
  const [userIsSubscriber, setUserIsSubscriber] = useState(false);

  const { loginWithRedirect, logout } = useAuth0();

  const getProfile = async () => {
    domEventsUtil.dispatch({
      name: eventsConstants.LOADING_ANIMATION,
      params: { show: true }
    });
    let result;
    try {
      if (user) {
        result = await userProfileService.getUserBasicProfile({
          token: user.token,
          userId: user._id,
        });

        setUserIsSubscriber(result.isSubscriber);

        if (window.location.href.includes('/config-account') && result.userType === 1) {
          window.open('/', '_self')
        }

        localStorage.setItem("user", JSON.stringify(result));
      }
    } catch (error) {
      throw error;
    } finally {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false },
      });
    }
  }

  useEffect(() => {
    getProfile();
  }, []);

  const handleIconClick = (event, path) => {
    event.stopPropagation();
    onItemClick(path);
  };
  const handleLogout = (event) => {
    domEventsUtil.dispatch({ name: eventsConstants.SIGN_OUT });
    logout({ logoutParams: { returnTo: window.location.origin } });

    // handleIconClick(event, pathsConstants.AUTHENTICATION);
  };

  return (
    <Wrapper id="menu-nav-item-list">
      <CreateAdItem
        onClick={event =>
          handleIconClick(event, userIsSubscriber ? pathsConstants.DASHBOARD_ADS
            : pathsConstants.PURCHASE_CHOOSE_TYPE)
        }
      >
        <Label>{userIsSubscriber ? 'cadastrar imóvel' : 'anunciar'}</Label>
      </CreateAdItem>
      
      {!isAuthenticated && (
        <SignUpBtn
          onClick={() => loginWithRedirect({ authorizationParams: { screen_hint: "signup", redirect_uri: `${window.location.origin}/authentication/signup/success?signup=true` } })}
        >
          <Label>cadastre-se</Label>
        </SignUpBtn>
      )}

      {!isAuthenticated && (
        <Item
          onClick={() => loginWithRedirect({ authorizationParams: { redirect_uri: `${window.location.origin}/authentication/login/success?login=true` } })}
        >
          <DoorIcon />
          <Label>entrar</Label>
        </Item>
      )}

      {isAuthenticated && (
        <Fragment>

          {document.URL.split('/')[3] !== "" && <Item onClick={event => handleIconClick(event, pathsConstants.HOME)}>
            <HomeIcon />
            <Label>Home</Label>
          </Item>}

          {user && user.userType === 2 && (

            <>
              {/* <Item
                onClick={event => handleIconClick(event, pathsConstants.PROFILE_SALE)}
              >
                <UserIcon />
                <Label>meu perfil</Label>
              </Item> */}
              <Item
                onClick={event => handleIconClick(event, pathsConstants.DASHBOARDADS_NAV)}
              >
                <DashboardIcon />
                <Label>dashboard</Label>
              </Item>
            </>
          )
          }

          {user && user.userType === 1 && (
            <Item
              onClick={event => handleIconClick(event, pathsConstants.CONFIG_ACCOUNT_COMMON_USER_PROFILE)}
            >
              <ConfigIcon />
              <Label>minha conta</Label>
            </Item>
          )}

          {user && user.userType === 2 && (
            <Item
              onClick={event => handleIconClick(event, pathsConstants.CONFIG_ACCOUNT_PLAN)}
            >
              <ConfigIcon />
              <Label>minha conta</Label>
            </Item>
          )}

          {user && user.userType >= 3 && (
            <>
              <Item
                onClick={event => handleIconClick(event, user.isLinked ? pathsConstants.CONFIG_ACCOUNT_PROFILE : pathsConstants.CONFIG_ACCOUNT_AD)}
              >
                <ConfigIcon />
                <Label>minha conta</Label>
              </Item>

              <Item
                onClick={event => handleIconClick(event, pathsConstants.PROFILE_SALE)}
              >
                <UserIcon />
                <Label>meu perfil</Label>
              </Item>
            </>
          )
          }
          {user && user.userType >= 3 && (
            <Item
              onClick={event => handleIconClick(event, pathsConstants.DASHBOARDADS_NAV)}
            >
              <DashboardIcon />
              <Label>dashboard</Label>
            </Item>
          )
          }
          {user && user.userType >= 3 && (
            <Item
              onClick={event => handleIconClick(event, `${pathsConstants.DASHBOARD_LEADS}?origin=koort`)}
            >
              <PeopleIcon />
              <Label>meus leads</Label>
            </Item>
          )
          }
          {user && user.userType >= 3 && (
            <Item
              onClick={event => handleIconClick(event, pathsConstants.BILLING_LEADS)}
            >
                <FileIcon />
                <Label>faturamento de leads</Label>
            </Item>
          )
          }
          <FavoriteItem
            onClick={event => handleIconClick(event, pathsConstants.FAVORITES_MY_FAVORITES)}
          >
            <HeartIcon />
            <Label>meus favoritos</Label>
          </FavoriteItem>
        </Fragment>
      )}

      <TextItem>
        <a href="http://blog.koort.com.br/"><Label>blog</Label></a>
      </TextItem>
      <TextItem onClick={event => handleIconClick(event, pathsConstants.ABOUT_US)}>
        <Label>quem somos</Label>
      </TextItem>
      <TextItem onClick={event => handleIconClick(event, '/koortimativa')}>
        <Label>KOORTIMATIVA</Label>
      </TextItem>
      <TextItem onClick={event => handleIconClick(event, pathsConstants.CONTACT)}>
        <Label>contato</Label>
      </TextItem>
      <TextItem onClick={event => handleIconClick(event, pathsConstants.TERMS_AND_CONDITIONS)}>
        <Label>termos e condições</Label>
      </TextItem>
      <TextItem onClick={event => handleIconClick(event, pathsConstants.DIVERSITY_AND_INCLUSION_POLICY)}>
        <Label>diversidade e inclusão</Label>
      </TextItem>
      <Spacer />

      {isAuthenticated && (
        <TextItem onClick={event => handleLogout(event)}>
          <Label>sair</Label>
        </TextItem>
      )}

      <Social>
        <Label>acompanhe o koort</Label>
        <SocialIcons />
      </Social>

    </Wrapper>
  );
};

NavItemList.propTypes = {
  isAuthenticated: propTypes.bool,
  onItemClick: propTypes.func,
};

NavItemList.defaultProps = {

};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 1px;
  overflow-y: scroll;
  padding-bottom: 100px;
  ${noScrollBar}
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  ${pointer};

  &:hover {
    & > label, & > svg {
      color: ${COLOR_1};
    }
    & > svg {
      transform: scale(1.2);
    }
  }
`;

const Label = styled(BaseLabel)`
  line-height: 30px;
  font-size: 14px;
  color: ${COLOR_2};
  align-self: center;
  text-decoration: none;
  opacity: 0.75;
  text-transform: uppercase;

  ${transitions(['color', 'transform', 'font-weight'], 'ease .3s')}
`;

const CreateAdItem = styled(Item)`
  margin-bottom: 10px;
  background-color: ${COLOR_13};
  padding: 15px 20px;
  display: flex;

  & > label {
    text-transform: uppercase;
    font-weight: 700;
    opacity: 1;
  }

  &:hover {
    & > label {
      color: ${COLOR_2};
      transform: scale(1.2);
    }
  }

  ${mqDesktop`
    display: none;
  `};

  ${mqTablet`
    margin-bottom: 30px;
  `};
`;

const SignUpBtn = styled(Item)`
  display: flex;
  margin-bottom: 30px;
  background-color: ${COLOR_1};
  padding: 15px 20px;

  & > label {
    text-transform: uppercase;
    font-weight: 700;
  }

  &:hover {
    & > label {
      color: ${COLOR_2};
      transform: scale(1.2);
    }
  }

  ${mqTablet`
    display: none;
  `};
`;

const FavoriteItem = styled(Item)`
  margin-bottom: 50px;
`;

const TextItem = styled(Item)`
  padding: 10px 20px;

  a {
    text-decoration: none;
    text-align: center;
  }

  &:hover {
    & > label {
      font-weight: 700;
    }
  }
`;

const Social = styled(Item)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 3rem;

  a {
    text-decoration: none;
    text-align: center;
  }

  &:hover {
    & > label {
      font-weight: 700;
    }
  }

  ${mqCustom(550)`
    display: none;
  `};
`;

const UserIcon = styled(User)`
  ${iconBaseStyle};
  color: ${COLOR_2};
  align-self: center;
  margin-right: 5px;
  height: 25px;
  opacity: 0.75;

  ${transitions(['color', 'transform'], 'ease .3s')}
`;

const HeartIcon = styled(UserIcon).attrs({
  as: Heart,
})``;

const HomeIcon = styled(UserIcon).attrs({
  as: Home,
})``;

const DoorIcon = styled(UserIcon).attrs({
  as: Enter,
})`
  height: 40px;
`;

const ConfigIcon = styled(UserIcon).attrs({
  as: Cogs,
})`
  height: 30px;
`;

const DashboardIcon = styled(UserIcon).attrs({
  as: Dashboard,
})`
  height: 22px;
`;

const PeopleIcon = styled(UserIcon).attrs({
  as: PeopleOutline,
})`
  height: 28px;
`;

const FileIcon = styled(UserIcon).attrs({
  as: File,
})`
  height: 28px;
`;

const Spacer = styled.div`
  flex-grow: 1;
  height: 1px;
`;

export default NavItemList;
